import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    textDecoration: "none",
  },

  container: {
    marginBottom: "30px",
    fontSize: "13px",
    minHeight: "39px",
  },

  link: {
    color: "#FFBB02",
    fontSize: "13px",
  },
})

const LinkToUpdate = ({
  classes,
  updateKeymans,
  isLoadingUpdateKeymans,
  keymanCountYstd,
  isShowUpdateInfo,
}) => {
  if (!isShowUpdateInfo) {
    return null
  }
  if (isLoadingUpdateKeymans || updateKeymans.length !== 0 || keymanCountYstd !== 0) {
    const keyman = updateKeymans.length !== 0 ? updateKeymans[0] : []
    return (
      <div className={classes.container}>
        {keyman.length !== 0 && (
          `${keyman.company_name}の${keyman.name}さんを含む`
        )}
        {keymanCountYstd}人が更新されました。
        <Link className={classes.link} to="/update">一覧はこちら</Link>
      </div>
    )
  } else {
    return (
      <div className={classes.container}>昨日更新された決裁者はいませんでした。</div>
    )
  }
}

LinkToUpdate.propTypes = {
  classes: PropTypes.object.isRequired,
  updateKeymans: PropTypes.array,
  isLoadingUpdateKeymans: PropTypes.bool,
  keymanCountYstd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

LinkToUpdate.defaultProps = {
  isShowUpdateInfo: true,
}

export default withStyles(styles)(LinkToUpdate)
