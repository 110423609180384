import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import * as actions from "../state/actions/keymans"
import { CORP_PER_PAGE } from "../constants/constants"
import BreadcrumbList from "../components/common/breadcrumb-list"
import Pagination from "../components/common/pagination"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import CorporationItem from "../components/common/corporation-item"
import { Location } from "@reach/router"
import { addCommas, getQueryVariable } from "../utils/str"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"

export const query = graphql`
  query CorpLocationQuery($slug: String) {
    corporationsLocationJson(fields: { slug: { eq: $slug } }) {
      value
      name
      prefecture_name
      city_name
      place_name
    }
  }
`

const mapStateToProps = (state, ownProps) => {
  return {
    corporations: state.corporations.corporations.toIndexedSeq().toArray(),
    pageInfo: state.corporations.pageInfo_corp,
    isLoading: state.corporations.isLoadingCorps,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.corporations.isInitialized,
    corporation: state.corporation.corporation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCorporations: (data, overwrite) =>
      dispatch(actions.getCorporations(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
    getCorporationsById: id => dispatch(actions.getCorporationsById(id)),
  }
}

const styles = theme => ({
  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },
})

class CorporationsLocation extends React.Component {
  componentDidMount() {
    this.props.getCorporations(this.getQuery(this.props), true)
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getCorporations(this.getQuery(this.props))
    }
  }

  getQuery = (props, query = {}) => {
    let locationQuery = {};
    if (props.data && props.data.corporationsLocationJson) {
      locationQuery = {
        city_name: [props.data.corporationsLocationJson.city_name]
      }
      return {
        paging: 1,
        per_page: CORP_PER_PAGE,
        page: 1,
        ...getQueryVariable(props.location.search, ["page"]),
        ...locationQuery,
        ...query,
      }
    }
  }

  render() {
    const {
      classes,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      data,
      path,
      isInitialized
    } = this.props
    const corporations =
      (!isInitialized) ||
      (isLoading && this.props.corporations && this.props.corporations.length === 0)
        ? Array.from(Array(CORP_PER_PAGE))
        : this.props.corporations

    return (
      <Layout hasFooterPopup={true}>
        {path && path.indexOf('/corporations-location-search') !== -1 && (
          <SEO
            title={`${data.corporationsLocationJson.value}の企業一覧`}
            description={`${data.corporationsLocationJson.value}の企業一覧です。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
            canonical={`https://keyman-db.smart-letter.com${path}`}
          />
        )}
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                  { path: `/corporations-location-search`, text: '地域から企業を探す' },
                  { path: `/corporations-location-search/${data.corporationsLocationJson.place_name}`, text: `${data.corporationsLocationJson.place_name}地方` },
                ]}
                current={{
                  path: path,
                  text: `${data.corporationsLocationJson.value}の企業一覧`,
                }}
              />
              <div>
                <h2 className="title">
                  {data.corporationsLocationJson.value}の企業一覧
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>

              {corporations.length > 0 && (
                <p className={classes.subheading}>
                  {pageInfo && pageInfo.total ? addCommas(pageInfo.total) : ' - '}
                  件の企業が見つかりました
                </p>
              )}

              {corporations.length === 0 ? (
                <div>企業がありません。</div>
              ) : (
                <div className="list">
                  {corporations.map((item, i) => (
                    <CorporationItem
                      item={item}
                      key={i}
                      showBorderBottom={i !== corporations.length - 1 || isLoading}
                    />
                  ))}
                </div>
              )}

              {corporations.length > 0 && pageInfo ? (
                <Pagination
                  currentPage={pageInfo.current_page}
                  lastPage={pageInfo.last_page}
                  perPage={CORP_PER_PAGE}
                  path={path ? path.slice(1) : ''}
                  total={pageInfo.total}
                  onChange={(data, overwrite) =>
                    this.props.getCorporations(this.getQuery(this.props, data), overwrite)
                  }
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>

            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <CorporationsLocation {...locationProps} {...props} />}
    </Location>
  ))
)
