import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { Link } from "gatsby"
import ArrowRightKey from "../icons/arrow-right-key"
import styled from 'styled-components'

const styles = theme => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },

  img: {
    width: "100%",
    height: "auto",
    minWidth: "48px",
    maxWidth: "48px",
    display: "block",
    margin: "auto",
  },

  rootBg: {
    padding: "22px 24px",
    height: "94px",
  },

  rootSm: {
    padding: "8px 10px",
    height: "64px",
  },

  icon: {
    minWidth: "48px",
    width: "48px",
    height: "48px",
    marginRight: "14px",
    background: "#F2F6F9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "24px",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    width: "calc(100% - 84px)",
    marginRight: "14px",
  },

  header: {
    fontWeight: "bold",
    color: "#181819",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },

  headerBg: {
    fontSize: "16px",
    marginBottom: "7px",
  },

  headerSm: {
    fontSize: "13px",
    marginBottom: "5px",
  },

  desc: {
    color: "#71737B",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  },

  descBg: {
    fontSize: "12px",
  },

  descSm: {
    fontSize: "11px",
  },

  headerBlock: {
    width: "35%",
    height: "16px",
    background: "#D8DBDF",
    marginBottom: "8px",
    borderRadius: "2px",
  },

  descBlock: {
    width: "90%",
    height: "12px",
    background: "#D8DBDF",
    borderRadius: "2px",
  },

  nameBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    overflow: "hidden",
  },

  name: {
    minWidth: "50%",
    display: "flex",
    flex: 1,
  },

  labelContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  label: {
    color: "#5A5B62",
    background: "#F4F6F9",
    fontSize: "10px",
    padding: "4px 10px",
    whiteSpace: "nowrap",
    marginBottom: "7px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
})

const CorporationItem = ({ classes, item, small, showBorderBottom }) => {
  let size = small ? "Sm" : "Bg"
  if (!item) {
    return (
          <div
            className={classNames(classes.root, classes[`root${size}`])}
            style={{ pointerEvents: "none", borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }}
          >
            <div className={classes.icon} style={{ background: "#F2F6F9" }}></div>
            <div className={classes.container}>
              <div className={classes.headerBlock}></div>
              <div className={classes.descBlock}></div>
            </div>
          </div>
    )
  } else {
    const logoFileName = item.logo_path.split('.').slice(0, -1).join('.')
    return (
      <Link to={`/corporations/${item.id}`}>
        <Root className={classNames(classes.root, classes[`root${size}`])}  style={{ borderBottom: showBorderBottom ? "1px solid #D8DBDF" : "" }} >
          <div className={classes.icon}>
            <picture>
              <source
                srcSet={logoFileName + ".webp"}
                height={48}
                width={48}
                className={classes.img}
                type="image/webp"
              />
              <img src={item.logo_path} alt="logo" className={classes.img} width={48} height={48} />
            </picture>
          </div>
          <div className={classes.container}>
            <div className={classes.nameBlock}>
              <NameContainer className={classes.name}>
                <span className={classNames(classes.header, classes[`header${size}`])}>
                  {item.company_name}
                </span>
              </NameContainer>
            </div>
            <div className={classNames(classes.desc, classes[`desc${size}`])}>
              <div className={classes.desc}>{item.address}</div>
            </div>
          </div>
          <ArrowContainer>
            <ArrowRightKey
              height={24}
              width={24}
              color="#1866FF"
            />
          </ArrowContainer>
        </Root>
      </Link>
    )
  }
}

const Root = styled.div`
  &:hover {
    cursor: pointer;
    background: #EFF7FF;
  }
`;

const ArrowContainer = styled.div`
  & {
    display: none;
  }
  ${Root}:hover & {
    display: block;
  }
`;

const NameContainer = styled.div`
  & {
    text-decoration: unset;
    color: #000000;
  }
  ${Root}:hover & {
    text-decoration: underline;
  }
`;

CorporationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  small: PropTypes.bool,
  showBorderBottom: PropTypes.bool,
}

CorporationItem.defaultProps = {
  small: false,
  showBorderBottom: true,
}

export default withStyles(styles)(CorporationItem)
