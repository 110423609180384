import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import * as dayjs from "dayjs"

import { addCommas } from "../../utils/str"

const styles = theme => ({
  root: {
    width: "100%",
    minHeight: "166px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 15px",
    boxSizing: "border-box",
    textAlign: "center",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px #254e781a",
  },

  text1: {
    fontSize: "16px",
    color: "#181819",
    marginBottom: "15px",

    "& span": {
      fontSize: "42px",
      fontWeight: "bold",
      marginRight: "6px",
    },
  },

  text2: {
    fontSize: "17px",
    color: "#181819",
    marginBottom: "2px",
  },

  text3: {
    fontSize: "11px",
    color: "#71737B",
  },
})

const Card = ({
  classes,
  style,
  keymansTotal,
  corporationsTotal,
  keymanCountYstd,
}) => (
  <div className={classes.root} style={style}>
    <div className={classes.text1}>
      <span>{addCommas(keymanCountYstd)}</span>人
    </div>
    <div className={classes.text2}>
      累計: {addCommas(corporationsTotal)}社{addCommas(keymansTotal)}人
    </div>
    <div className={classes.text3}>{dayjs().format("YYYY年M月D日現在")}</div>
  </div>
)

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  keymansTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  corporationsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  keymanCountYstd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default withStyles(styles)(Card)
